@import 'node_modules/bootstrap/scss/bootstrap.scss';
// @import './Viewer.scss';

body {
  font-family: 'cera-pro-regular' !important;
  background-image: url('./static/bb_bg2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

// [data-amplify-authenticator] {
  // --amplify-colors-background-primary: var(--amplify-colors-black);
  // --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);
//   --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
//   --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
//   --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
//   --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
//   --amplify-colors-font-interactive: var(--amplify-colors-white);
//   --amplify-components-tabs-item-active-color: var(--amplify-colors-white);
//   --amplify-components-tabs-item-focus-color: var(--amplify-colors-white);
//   --amplify-components-tabs-item-hover-color: var(--amplify-colors-orange);
// }

.amplify-button
{
  background-color: black !important;
  border: 1px solid white !important;
}

.amplify-label 
{
  color: white !important;
}

@font-face {
  font-family: 'cera-pro-regular';
  src: url('./static/cerapro-regular.eot'),
       url('./static/cerapro-regular.woff');
  font-weight: normal;
  font-style:normal
}

.cera-pro-regular {
  font-family: 'cera-pro-regular' !important;
  font-style: normal;
  speak: none;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

.rootContainer{
  // height:1200px;
  // display:flex;
  // flex-direction: column;

  // opacity:0.7;
  
  // background-blend-mode: luminosity;
}
h1 {
  font-family: 'cera-pro-regular';
}
.appContainer
{
  // background-color: white;
  // height:100vh;
  // opacity:0.7;
}
.appContainer::after{
//   // background-attachment: fixed;
  // background-image:linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
//   backdrop-filter: "blur(10px)"; 

}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.customCardColumnCount
{
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  // -webkit-column-gap: 1em;
  // -moz-column-gap: 1em;
  // column-gap: 1em;
  margin-top: 10px;
    margin-left: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: .5rem;

}

.lsCardImage.card-img-top
{
    margin-left: 8px;
    /* margin-right: 20px !important; */
    width: 95%;
    margin-top: -30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    margin-bottom:15px;

}

.projectListCardColumns
{
  column-count:4;
}

.navBarCustom
{
  background-color:rgba(255,255,255,0.3) !important;
}

.dashboardWelcomePanel
{
  font-size:2.5em;
  font-family: 'cera-pro-regular' !important;
  color: white;
  
}

p.dashboardWelcomePanel
{
  text-align: right;
}

.projectCard
{
  // width: 18rem;
  font-family: 'cera-pro-regular' !important;

  flex: 1;
  margin: 10px;
  width:18rem;
  max-width:18rem;
}

.projectCardBack
{
  font-family: 'cera-pro-regular' !important;

  background-color:#282c34;
  color:#ffffff;
}
.projectCardBackTitle{
  color: #ffffff;
}

.projectsCardColumns
{
  column-count:4;
  // height:100vh;
}

.rightAlignFooter
{
  display: flex;
    align-items: right;
    justify-content: right;
    flex: 1;
    height: 100%;
}

.panelSeparator
{
  width:50px;
  border-left:1px dashed rgba(0,0,0,0.1);
  margin-left:2px;
  margin-right:2px;
}

.projectSheetRightPanel
{
  text-align: left;
  margin-left:0px;
  padding-left:0px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size:3em;
}

.projectSheetCoverImage
{
  border: 0px;
}

.transparentToggle
{
  background-color:transparent !important;
  border: none;
}

.navBarBadge
{
  // height:24px;
}

 .launchButton
 {
  width:100px;
  height:100px;
  // margin-top:100px;
 }

 #launchContainer
 {
  height: 98vh !important;
  margin:0px !important;
  // background-color:rgba(0,0,0,0.85) !important;
  
 }

 .appContainer
 {
  background-color:rgba(0,0,0,0.85) !important;
  height:100vh;
 }

 #innerAppContainer
 {
  margin:0px;
  padding:0px;
 }

 .dashboardContainer
 {
  padding:50px;
 }

 .launchCard
 {
  background-color: black;
  padding:20px;
  width: 400px;
  height: 290px;
  // border: red 1px solid;
  border-radius: 10px;
  // display: flex;
  // justify-content: center;
  // overflow: none;
  cursor: pointer;
 }

 .launchCardLogo
 {
  width:105px;
  height: 250px;
  margin-bottom:30px;
 }